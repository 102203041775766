<template>
  <div class="page-wrapper">
    <div class="jumbotron">
      <div class="container">
        <h2 class="mb-4 heading" data-aos="fade-down">Gallery</h2>
      </div>
    </div>
    <div class="container">
      <v-tabs class="tabs" v-model="tabs">
        <v-tab>Infrastructure</v-tab>
        <v-tab>Products</v-tab>
        <v-tab>Celebrations</v-tab>
      </v-tabs>
      <div class="row">
        <div class="col-12">
          <v-tabs-items v-model="tabs">
            <!-- //Infrastructure tab -->
            <v-tab-item>
              <div class="row tm-mb-90">
                <div
                  class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                  :key="index"
                  v-for="(gallery, index) in infrastructure"
                >
                  <span>
                    <viewer :options="options">
                      <figure class="image-item">
                        <img
                          :src="gallery.images"
                          alt="Image"
                          class="img-fluid"
                        />
                      </figure>
                    </viewer>
                    <div class="tm-text-gray" style="text-align: center">
                      <span class="tm-text-gray-light text-uppercase">{{
                        gallery.name
                      }}</span>
                    </div>
                  </span>
                </div>
              </div>
            </v-tab-item>
            <!-- Products tab -->
            <v-tab-item>
              <div class="row tm-mb-90 tm-gallery">
                <div
                  class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                  :key="index"
                  v-for="(gallery, index) in products"
                >
                  <span>
                    <viewer :options="options">
                      <figure class="image-item">
                        <img
                          :src="gallery.images"
                          alt="Image"
                          class="img-fluid"
                        /></figure
                    ></viewer>
                    <div class="tm-text-gray" style="text-align: center">
                      <span class="tm-text-gray-light text-uppercase">{{
                        gallery.name
                      }}</span>
                    </div>
                  </span>
                </div>
              </div>
            </v-tab-item>
            <!-- Celebrations tab -->
            <v-tab-item>
              <div class="row tm-mb-90">
                <div
                  class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-5"
                  :key="index"
                  v-for="(gallery, index) in celebration"
                >
                  <span
                    ><viewer :options="options">
                      <figure class="image-item">
                        <img
                          :src="gallery.images"
                          alt="Image"
                          class="img-fluid"
                        /></figure
                    ></viewer>
                    <div class="tm-text-gray" style="text-align: center">
                      <span class="tm-text-gray-light text-uppercase">{{
                        gallery.name
                      }}</span>
                    </div>
                  </span>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "../firebase.js";
export default {
  name: "Gallery",
  data() {
    return {
      tabs: null,
      infrastructure: [],
      products: [],
      celebration: [],
      options: {
        button: true,
        title: false,
        toolbar: false,
        navbar: false,
        tooltip: false,
        movable: false,
        rotatable: false,
        scalable: false
      }
    };
  },
  created() {
    db.collection("Gallerys")
      .orderBy("name", "asc")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          let gallery = doc.data();
          gallery.id = doc.id;
          if (gallery.category == "Infrastructure") {
            this.infrastructure.push(gallery);
          } else if (gallery.category == "Products") {
            this.products.push(gallery);
          } else if (gallery.category == "Celebrations") {
            this.celebration.push(gallery);
          }
          // this.Gallerys.push(gallery);
        });
      });
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Gallery",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s"
  }
};
</script>
<style scoped>
.marginname {
  margin: auto;
}
.image-item {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 250px;
  border: ridge;
}

.image-item img {
  position: relative;
  display: block;
  height: -webkit-fill-available;
  width: 100%;
}

.image-item p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

.jumbotron {
  background: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.4),
        rgba(5, 6, 116, 0.6)
      )
      fixed,
    url("../assets/img/manufacture.jpg") no-repeat center center;
}
.tabs {
  margin-bottom: 20px;
  color: #010181;
}
.heading {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}
@media (max-width: 930px) {
  .image-item {
    width: 70%;
    margin: auto;
  }
}
</style>
